// Need to use the React-specific entry point to import createApi
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from "../store";
import jwt_decode, {JwtPayload} from "jwt-decode";



// Define a service using a base URL and expected endpoints
export const api = createApi({
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, {getState, endpoint}) => {

      //No token on login
      if(endpoint === 'postAuth') {
        return headers;
      }

      const auth = (getState() as RootState).auth;
      const token = auth.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        const {exp} = jwt_decode(token) as JwtPayload;
        if(exp && exp > (new Date().getTime() / 1000)) {
          headers.set('Authorization', `Bearer ${token}`);
        }
      }

      return headers;
    },
    baseUrl: process.env.REACT_APP_PROTECTOR_SERVER,
  }),
  endpoints: () => ({}),
  tagTypes: ['User'],
});
