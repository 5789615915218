import React, { useCallback, useState } from 'react';
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Add,
  CheckBox,
  CheckBoxOutlineBlank,
  Edit,
  Password,
} from '@mui/icons-material';

import ModeratorModal from '../../components/Modals/ModeratorModal';
import Header from '../../components/Header/Header';

import {
  useCreateUserMutation,
  useGetAllUsersQuery,
  UserQueryResponse,
  useUpdatePasswordMutation,
  useUpdateUserMutation,
} from '../../redux/services/userAdmin';
import { useLocation } from 'react-router';
import PasswordModal from '../../components/Modals/PasswordModal';
import { useMutationToasts } from '../../utils/hooks';

export default function ModeratorUsers() {
  const allUsersResult = useGetAllUsersQuery();
  const [createUser, createUserResult] = useCreateUserMutation();
  const [updateUser, updateUserResult] = useUpdateUserMutation();
  const [updatePassword, updatePasswordResult] = useUpdatePasswordMutation();
  const location = useLocation();
  const [target, setTarget] = useState('');
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<UserQueryResponse | null>(null);

  const rows = allUsersResult?.data || [];

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const editModeratorHandler = useCallback(
    (row: any) => {
      setCurrentRow(row);
      setTarget('edit_moderator');
      setOpen(true);
    },
    [open, target, currentRow]
  );

  const changePasswordHandler = useCallback(
    (row: any) => {
      setCurrentRow(row);
      setTarget('change_password');
      setOpen(true);
    },
    [open, target, currentRow]
  );

  const addModeratorHandler = useCallback(() => {
    setTarget('add_new_moderator');
    setCurrentRow(null);
    setOpen(true);
  }, [open, target]);

  function handleValidSubmitModeratorModal(formValues) {
    if (target === 'add_new_moderator') {
      createUser(formValues);
    } else if (target === 'edit_moderator') {
      const id = currentRow?.id as string;
      updateUser({ id, record: formValues });
    }
  }

  function handleValidSubmitPasswordModal(formValues) {
    const id = currentRow?.id as string;
    updatePassword({ id, password: formValues.password });
  }

  useMutationToasts({
    isLoading: createUserResult.isLoading,
    isSuccess: createUserResult.isSuccess,
    error: createUserResult.error,
    successMsg: 'El usuario ha sido creado.',
    onSuccess: () => {
      handleClose();
      allUsersResult.refetch();
    },
  });

  useMutationToasts({
    isLoading: updateUserResult.isLoading,
    isSuccess: updateUserResult.isSuccess,
    error: updateUserResult.error,
    successMsg: 'El usuario ha sido modificado.',
    onSuccess: () => {
      handleClose();
      allUsersResult.refetch();
    },
  });

  useMutationToasts({
    isLoading: updatePasswordResult.isLoading,
    isSuccess: updatePasswordResult.isSuccess,
    error: updatePasswordResult.error,
    successMsg: 'La contraseña ha sido cambiada.',
    onSuccess: handleClose,
  });

  const isModeratorModalOpen =
    target === 'add_new_moderator' ||
    (currentRow !== null && target === 'edit_moderator');

  const isPasswordModalOpen =
    currentRow !== null && target === 'change_password';

  return (
    <>
      <Header />
      <TableContainer component={Paper} sx={{ flexGrow: 1 }}>
        <Table aria-label="simple table" style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell>Usuario</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Rol</TableCell>
              <TableCell align="center">Deshabilitado</TableCell>
              <TableCell align="right">
                <Button variant="contained" onClick={addModeratorHandler}>
                  <Add /> Agregar moderador
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.login}</TableCell>
                <TableCell>{row.full_name}</TableCell>
                <TableCell>{row.role}</TableCell>
                <TableCell align="center">
                  {row.disabled ? <CheckBox /> : <CheckBoxOutlineBlank />}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="Modificar"
                    onClick={() => {
                      editModeratorHandler(row);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label="Cambiar contraseña"
                    onClick={() => {
                      changePasswordHandler(row);
                    }}
                  >
                    <Password />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {isModeratorModalOpen && (
            <ModeratorModal
              open={open}
              onClose={handleClose}
              target={target}
              currentRow={currentRow}
              onValidSubmit={handleValidSubmitModeratorModal}
            />
          )}
          {isPasswordModalOpen && (
            <PasswordModal
              open={open}
              onClose={handleClose}
              currentRow={currentRow}
              onValidSubmit={handleValidSubmitPasswordModal}
            />
          )}
        </Table>
      </TableContainer>
    </>
  );
};