import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from '@mui/material';
import { useLazyPostAuthQuery } from '../../redux/services/auth';
import { useDispatch } from 'react-redux';
import { setToken } from '../../redux/authSlice';
import {useFormik} from "formik";

const LoginPage = () => {
  const [
      postAuth, {
      data,
      isSuccess,
      error,
      isError, isFetching
  }] = useLazyPostAuthQuery();
  const [authenticationError, setAuthenticationError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!isFetching && isError) {
      //@ts-ignore
      setAuthenticationError(error?.data?.errorCode ?? 'Error de autenticación.');
    }
  }, [isError, isFetching]);

  useEffect(() => {
    if (isSuccess) {
      //@ts-ignore
      dispatch(setToken(data?.jwttoken));
      navigate('/concepts');
    }
  }, [isSuccess]);

  function validate(values) {

    const errors = {};

    if (!values.login) {
      //@ts-ignore
      errors.login = 'obligatorio';
    }

    if (!values.password) {
      //@ts-ignore
      errors.password = 'obligatorio';
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validate,
    onSubmit: (values, actions) => {
      setAuthenticationError(null);
      postAuth({ login: values.login, password: values.password });
      actions.setSubmitting(false);
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" fontFamily="'National 2 Compressed'">
          Administración de Ciudadano Protector
        </Typography>

        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={formik.handleSubmit}
        >
          {Boolean(authenticationError) && (
            <Typography color="red">{authenticationError}</Typography>
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            id="login"
            label="Usuario"
            name="login"
            autoComplete="login"
            autoFocus
            inputProps={{ maxLength: 30 }}
            onChange={formik.handleChange}
            value={formik.values.login}
            error={Boolean(formik.touched.login && formik.errors.login)}
            helperText={formik.errors.login && formik.errors.login}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            inputProps={{ maxLength: 30 }}
            onChange={formik.handleChange}
            value={formik.values.password}
            autoComplete="current-password"
            error={Boolean(formik.touched.password && formik.errors.password)}
            helperText={(formik.errors.password && formik.errors.password) || authenticationError}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Ingresar
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
