import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, MenuItem, Select,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

import { validationSchemaConcept } from '../../constants/ValidationSchemas';
import { formFieldsGuide } from '../../constants/FormFields';
import {ConceptCategory} from "../../redux/services/conceptCategory";
import {toast} from "react-toastify";


type Props = {
  open: boolean;
  onClose(): void;
  target: string;
  currentRow: any;
  conceptCategories: ConceptCategory[];
  onValidSubmit(formValues: FormValues): void;
};

type FormValues = {
  name: string;
  category_id: string;
  description_html: string;
  description2_html: string;
  hashtags: string;
  image_url: string;
  detail_image_url: string;
  disabled: boolean;
};

type formikFields =
  | 'name'
  | 'category'
  | 'description_html'
  | 'description2_html'
  | 'hashtags'
  | 'image_url'
  | 'detail_image_url'
  | 'disabled';

export default function ConceptModal({
  open,
  onClose = () => {},
  target,
  currentRow,
  conceptCategories=[],
  onValidSubmit = () => {},
}: Props) {

  const formik= useFormik({
    initialValues: {
      name: currentRow?.name ?? '',
      category_id: currentRow?.category_id ?? '',
      description_html: currentRow?.description_html ?? '',
      description2_html: currentRow?.description2_html ?? '',
      hashtags: currentRow?.hashtags ?? '',
      image_url: currentRow?.image_url ?? '',
      detail_image_url: currentRow?.detail_image_url ?? '',
      disabled: currentRow?.disabled ?? false,
    },
    onSubmit: () => onValidSubmit(formik.values),
    validationSchema: validationSchemaConcept,
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        name: currentRow?.name ?? '',
        category_id: currentRow?.category_id ?? '',
        description_html: currentRow?.description_html ?? '',
        description2_html: currentRow?.description2_html ?? '',
        hashtags: currentRow?.hashtags ?? '',
        image_url: currentRow?.image_url ?? '',
        detail_image_url: currentRow?.detail_image_url ?? '',
        disabled: currentRow?.disabled ?? false,
      }
    });
  }, [currentRow]);

  function handleSubmit() {
    onValidSubmit(formik.values);
  }

  function handleClose() {
    onClose();
    formik.resetForm();
  }

  return (
    <Dialog fullWidth open={open} onClose={handleSubmit}>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <DialogTitle>
          {target === 'add_concept' ? (
            <div> Agregar concepto </div>
          ) : (
            <div> Modificar concepto </div>
          )}
        </DialogTitle>
        <DialogContent>
          {formFieldsGuide.map(({ field, label }) => {
            if(field === 'category_id' ) {
              return (
                <div key={field}>
                  <Select
                    error={Boolean(
                      formik.touched[field as formikFields] &&
                      formik.errors[field as formikFields]
                    )}
                    margin="dense"
                    id={field}
                    label={label}
                    fullWidth
                    multiline
                    displayEmpty
                    required
                    variant="standard"
                    name={field}
                    value={formik.values[field as formikFields]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    // defaultValue={initialValues[field as formikFields]}
                  >
                    <MenuItem value="">--Escoge categoría--</MenuItem>
                    {conceptCategories.map(it => (
                      <MenuItem key={`category-item-${it.category_id}`} value={it.category_id}>{it.name}</MenuItem>
                    ))}
                  </Select>
                </div>
              )
            }
            return (
              <div key={field}>
                <TextField
                  error={Boolean(
                    formik.touched[field as formikFields] &&
                      formik.errors[field as formikFields]
                  )}
                  helperText={
                    formik.touched[field as formikFields] &&
                    formik.errors[field as formikFields]
                  }
                  margin="dense"
                  id={field}
                  label={label}
                  type="text"
                  fullWidth
                  multiline
                  variant="standard"
                  name={field}
                  value={formik.values[field as formikFields]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            onClick={handleSubmit}
            type="button"
          >
            {target === 'add_concept' ? 'Agregar' : 'Modificar'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
