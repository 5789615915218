import { api } from './api';
import {
  GetReportImagePayload,
  GetReportPayload,
  Page, PaginationCriteria,
  ReportModerationMutationPayload,
} from '../../utils/types';

export type FilterCriteria = PaginationCriteria & {
  type: string;
  text: string;
  status: string;
};

export const reportApi = api.injectEndpoints({
  endpoints: build => ({
    getFilterReports: build.query<Page<GetReportPayload>, FilterCriteria>({
      query: (params) => {
        const urlParams = new URLSearchParams(params as unknown as Record<string, string>);
        return {
          url: `/adminIncidentReport?${urlParams.toString()}`,
          method: 'GET',
        };
      },
    }),
    getReportImages: build.query<GetReportImagePayload[], Number>({
      query: incidentReportId => {
        return {
          url: `/incidentReport/${incidentReportId}/images`,
          method: 'GET',
        };
      },
    }),
    putReportModeration: build.mutation<void, Partial<{ incidentReportId: number, record: ReportModerationMutationPayload}>>({
      query({incidentReportId, record}) {
        return {
          url: `/adminIncidentReport/${incidentReportId}`,
          method: 'PUT',
          body: record,
        };
      },
    }),
  }),
});

export const {
  useLazyGetFilterReportsQuery,
  useLazyGetReportImagesQuery,
  usePutReportModerationMutation,
} = reportApi;
