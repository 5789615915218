import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material';
import { GetReportImagePayload, GetReportPayload } from '../../utils/types';
import { getReportTypeLabel } from '../../utils/utils';
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";

type Props = {
  open: boolean;
  onClose(): void;
  target: string;
  currentRow: GetReportPayload;
  reportImages: GetReportImagePayload[];
};

export default function IncidentReportDetailModal({
  open,
  onClose = () => {},
  currentRow,
  reportImages = [],
}: Props) {

  return (
    <Dialog fullWidth maxWidth="md" open={open}>
        <DialogTitle>
          <div>Detalles de reporte de incidente</div>
        </DialogTitle>
        <DialogContent>

          <Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>Tipo de reporte</Grid>
              <Grid item xs={6}>{getReportTypeLabel(currentRow.type)}</Grid>
              <Grid item xs={4}>Título</Grid>
              <Grid item xs={6}>{currentRow.title}</Grid>
              <Grid item xs={4}>Descripción</Grid>
              <Grid item xs={6}>{currentRow.description}</Grid>
              <Grid item xs={4}>Localización</Grid>
              <Grid item xs={6}>{currentRow.location_description}</Grid>
              <Grid item xs={4}>Coordenadas</Grid>
              <Grid item xs={6}>
                {currentRow.x_coordinate?.toFixed(6)}°E, {currentRow.y_coordinate?.toFixed(6)}°N
              </Grid>
              <Grid item xs={4}>Resolución</Grid>
              <Grid item xs={6}>{currentRow.status === 'RESOLVED' ? 'Resuelto' : 'Sin resolver'}</Grid>
              <Grid item xs={4}>Permiso</Grid>
              <Grid item xs={6}>{currentRow.permit_sign_text}</Grid>
            </Grid>
          </Typography>

          <ImageList>
            {currentRow.permit_sign_image_url && (
              <ImageListItem>
                <img
                  src={`${currentRow.permit_sign_image_url}`}
                  loading="lazy"
                  alt="Imagen de permiso"
                />
              </ImageListItem>
            )}
          </ImageList>


          <Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>Cuenca</Grid>
              <Grid item xs={6}>{currentRow.watershed_name}</Grid>
              <Grid item xs={4}>Creado por</Grid>
              <Grid item xs={6}>{currentRow.created_by_user_full_name} ({currentRow.created_by_user_email})</Grid>
              <Grid item xs={4}>Modificado por</Grid>
              <Grid item xs={6}>{currentRow.created_by_user_full_name} ({currentRow.updated_by_user_email})</Grid>
              <Grid item xs={4}>Censurado</Grid>
              <Grid item xs={6}>
                {currentRow.flagged ? <CheckBox /> : <CheckBoxOutlineBlank />}
              </Grid>
              <Grid item xs={4}>Censurado por</Grid>
              <Grid item xs={6}>{currentRow.flagged_by}</Grid>
              <Grid item xs={4}>Fecha de censura</Grid>
              <Grid item xs={6}>{currentRow.flagged_at}</Grid>
              <Grid item xs={4}>Comentario de censura</Grid>
              <Grid item xs={6}>{currentRow.flagged_comment}</Grid>
            </Grid>
          </Typography>

          <Typography variant="h6">Imágenes</Typography>
          <ImageList>
            {reportImages.map(item => {
              return (
                <ImageListItem key={`report-image-${item.image_id}`}>
                  <img
                    src={`${item.url}`}
                    loading="lazy"
                   alt="Imagen de reporte"/>
                </ImageListItem>
              )
            })}
          </ImageList>

        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Regresar
          </Button>
        </DialogActions>
    </Dialog>
  );
}
