import React, {useEffect} from 'react';
import { useFormik } from 'formik';
import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel, MenuItem, Select,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

import {
  validationSchemaAddModerator,
  validationSchemaEditModerator,
} from '../../constants/ValidationSchemas';
import {
  formFieldsAddModerator,
  formFieldsEditModerator,
} from '../../constants/FormFields';
import {toast} from "react-toastify";

type formikFields =
  | 'login'
  | 'full_name'
  | 'role'
  | 'password'
  | 'repeatPassword'
  | 'disabled';

type ModeratorModalProps = {
  open: boolean;
  onClose(): void;
  target: string;
  currentRow: any;
  onValidSubmit(formValues: FormValues): void;
};

type FormValues = {
  login?: string;
  full_name?: string;
  role?: string;
  password?: string;
  repeatPassword?: string;
  disabled: boolean;
};


const ModeratorModal = ({
  open,
  onClose = () => {},
  target,
  currentRow,
  onValidSubmit = () => {},
}: ModeratorModalProps) => {

  const formik = useFormik({
    initialValues: {
      login: currentRow?.login ?? '',
      full_name: currentRow?.full_name ?? '',
      role: currentRow?.role ?? 'moderator',
      password: '',
      repeatPassword: '',
      disabled: currentRow?.disabled ?? false,
    } as FormValues,
    onSubmit: () => handleSubmit(),
    validationSchema:
      target === 'add_new_moderator'
        ? validationSchemaAddModerator
        : validationSchemaEditModerator,
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        login: currentRow?.login ?? '',
        full_name: currentRow?.full_name ?? '',
        role: currentRow?.role ?? 'moderator',
        password: '',
        repeatPassword: '',
        disabled: currentRow?.disabled ?? false,
      }
    });
  }, [currentRow]);

  function handleSubmit() {
    if(formik.values.password !== formik.values.repeatPassword) {
      toast.error('Contraseñas no son iguales.');
      return;
    }
    onValidSubmit(formik.values);
  }

  function handleClose() {
    onClose();
    formik.resetForm();
  }

  const currentFormField =
    target === 'add_new_moderator'
      ? formFieldsAddModerator
      : formFieldsEditModerator;

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <DialogTitle>
          {target === 'add_new_moderator' ? (
            <div>Agregar moderador</div>
          ) : (
            <div>Modificar moderador</div>
          )}
        </DialogTitle>
        <DialogContent>

          {/*<pre style={{fontStyle: 'small'}}>{JSON.stringify(currentRow, null, 2)}</pre>*/}

          {currentFormField.map(({ field, label }) => {
            if(field === 'disabled') {
              return (
                <div key={field}>
                  <FormControlLabel control={<Checkbox
                    checked={formik.values.disabled}
                    id={field}
                    name={field}
                    value={formik.values[field as formikFields]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />} label={label} />
                </div>
              );
            }
            if(field === 'role') {
              return (
                <div key={field}>
                  <Select
                    error={Boolean(
                      formik.touched[field as formikFields] &&
                      formik.errors[field as formikFields]
                    )}
                    margin="dense"
                    id={field}
                    label={label}
                    fullWidth
                    multiline
                    required
                    variant="standard"
                    name={field}
                    value={formik.values[field as formikFields]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    // defaultValue={initialValues[field as formikFields]}
                  >
                    <MenuItem value="moderator">Moderador</MenuItem>
                    <MenuItem value="admin">Administrador</MenuItem>
                  </Select>
                </div>
              );
            }
            return (
              <div key={field}>
                <TextField
                  error={Boolean(
                    formik.touched[field as formikFields] &&
                      formik.errors[field as formikFields]
                  )}
                  helperText={
                    formik.touched[field as formikFields] &&
                    formik.errors[field as formikFields]
                  }
                  margin="dense"
                  id={field}
                  label={label}
                  type={field === 'login' ? 'email' : ['password', 'repeatPassword'].includes(field) ? 'password' : 'text'}
                  fullWidth
                  variant="standard"
                  name={field}
                  value={formik.values[field as formikFields]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            onClick={handleSubmit}
            type="button"
          >
            {target === 'add_new_moderator' ? 'Agregar' : 'Modificar'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModeratorModal;
