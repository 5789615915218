import * as yup from 'yup';

export const validationSchemaConcept = yup.object().shape({
  name: yup
    .string()
    .min(5, 'Debe tener al menos 5 caracteres.')
    .max(50, 'Must be 50 characters or less')
    .max(2000, 'La descripción puede tener hasta 2000 caracteres.'),
  category_id: yup
    .number()
    .required('La categoría del concepto es obligatoria.'),
  description_html: yup
    .string()
    .min(5, 'Debe tener al menos 5 caracteres.')
    .max(2000, 'La descripción puede tener hasta 2000 caracteres.')
    .required('La descripción del concepto es obligatoria.'),
  description2_html: yup
    .string()
    .min(5, 'Debe tener al menos 5 caracteres.')
    .max(2000, 'La descripción extendida puede tener hasta 2000 caracteres.'),
  hashtags: yup
    .string()
    .min(3, 'Must be at least 5 characters.')
    .max(200, 'Must be 200 characters or less.'),
  image_url: yup.string(),
  detail_image_url: yup.string(),
});

export const validationSchemaModerate = yup.object().shape({
  flagged: yup.boolean(),
  flagged_comment: yup.string()
    .max(1000, 'El comentario puede tener hasta mil (1000) caracteres.'),
  status: yup.string()
    .required('Es estatus del reporte es obligatorio.'),
});

export const validationSchemaNotifContact = yup.object().shape({
  email: yup
    .string()
    .required('El correo es obligatorio')
    .email('Debe ser un correo electrónico'),
  full_name: yup
    .string()
    .required('El nombre completo es obligatorio')
    .min(3, 'Debe tener al menos tres (3) caracteres.'),
  disabled: yup.boolean(),
});

const moderatorFields = {
  login: yup
    .string()
    .required('El correo es obligatorio')
    .email('Debe ser un correo electrónico'),
  full_name: yup
    .string()
    .required('El nombre completo es obligatorio')
    .min(3, 'Debe tener al menos tres (3) caracteres.'),
  role: yup.string().required('El rol es obligatorio'),
  disabled: yup.boolean(),
};


const passwordFields = {
  password: yup
    .string()
    .required('La contraseña es obligatoria.')
    .min(6, 'Debe tener al menos seis (6) caracteres.'),
  repeatPassword: yup
    .string()
    .required('La confirmación de la contraseña es obligatoria.')
    .min(6, 'Debe tener al menos seis (6) caracteres.'),
};

export const validationSchemaAddModerator = yup.object().shape({
  ...moderatorFields,
  ...passwordFields,
});

export const validationSchemaEditModerator = yup
  .object()
  .shape(moderatorFields);

export const validationSchemaChangePassword = yup
  .object()
  .shape(passwordFields);
