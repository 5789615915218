import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import LoginPage from './views/Login/LoginPage';
import ModeratorUsers from './views/ModeratorUsers/ModeratorUsers';
import Concepts from './views/Concepts/Concepts';
import IncidentReports from './views/IncidentReports/IncidentReports';
import { ToastContainer } from 'react-toastify';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import {createTheme, ThemeProvider} from "@mui/material";
import './App.css';
import NotifContacts from "./views/NotifContacts/NotifContacts";

export default function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: 'rgba(66, 74, 220, 1)',
      }
    },
    typography: {
      fontFamily: "'Questa Sans'",
      button: {
        fontFamily: "'National 2 Compressed'",
        fontSize: 18,
      }
    }
  });
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/concepts" element={<Concepts/>}/>
            <Route path="/incidentReports" element={<IncidentReports/>}/>
            <Route path="/notifContacts" element={<NotifContacts/>}/>
            <Route path="/moderatorUsers" element={<ModeratorUsers/>}/>
            <Route path="*" element={<Navigate to="/login" replace/>}/>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
      <ToastContainer theme="colored"/>
    </Provider>
  );
}