import {api} from './api';
import {Page} from "../../utils/types";

export type ConceptCategory = {
  category_id: number;
  name: string;
};

export const conceptCategoryApi = api.injectEndpoints({
  endpoints: build => ({
    getConceptCategories: build.query<Page<ConceptCategory>, void>({
      query: () => '/conceptCategory',
    }),
  }),
});

export const {useGetConceptCategoriesQuery} = conceptCategoryApi;
