import React, { memo } from 'react';
import {Link, NavLink} from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Toolbar,
  Typography,
} from '@mui/material';

export default function Header() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, mr: '200px' }}
            fontFamily="'National 2 Compressed'"
            fontWeight="bold"
            fontSize={28}
          >
            Ciudadano Protector Admin
          </Typography>

          <ButtonGroup>
            <Button variant="contained" size="large" color="primary"
                    component={NavLink} to="/concepts">
              Conceptos
            </Button>
            <Button variant="contained" size="large" color="primary"
                    component={NavLink} to="/incidentReports">
              Reportes
            </Button>
            <Button variant="contained" size="large" color="primary"
                    component={NavLink} to="/notifContacts">
              Contactos
            </Button>
            <Button variant="contained" size="large" color="primary"
                    component={NavLink} to="/moderatorUsers">
              Moderadores
            </Button>
            <Button variant="contained" size="large" color="error"
                    component={NavLink} to="/login">
              Salir
            </Button>
          </ButtonGroup>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
