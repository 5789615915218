import {api} from './api';
import {AuditFieldsType, Page} from "../../utils/types";

export type UserMutationRequest = {
  login: string;
  full_name: string;
  image: string;
  role: string;
};

export type UserQueryResponse = UserMutationRequest & {
  id: string;
  disabled: boolean;
} & AuditFieldsType;

export const userAdminApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllUsers: build.query<UserQueryResponse[], void>({
      query: () => '/admin',
    }),
    createUser: build.mutation<void, Partial<UserMutationRequest>>({
      query(data) {
        return {
          url: '/admin',
          method: 'POST',
          body: data,
        };
      },
    }),
    updateUser: build.mutation<
      void,
      { id: string; record: Partial<UserMutationRequest> }
    >({
      query({ id, record }) {
        return {
          url: `/admin/${id}`,
          method: 'PUT',
          body: record,
        };
      },
    }),
    updatePassword: build.mutation<void, { id: string; password: string }>({
      query({ id, password }) {
        return {
          url: `/admin/${id}/password`,
          method: 'PATCH',
          body: { password },
        };
      },
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdatePasswordMutation,
} = userAdminApi;
