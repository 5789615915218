import {api} from './api';
import {ConceptMutationPayload, GetConceptPayload, Page, ReportType} from "../../utils/types";
import {UserMutationRequest} from "./userAdmin";


export const conceptApi = api.injectEndpoints({
  endpoints: build => ({
    getConcepts: build.query<Page<GetConceptPayload>, void>({
      query: () => '/concept',
    }),
    getConcept: build.query<GetConceptPayload, number>({
      query: conceptId => `/concept/${conceptId}`,
    }),
    getRelatedConcepts: build.query<GetConceptPayload[], number>({
      query: conceptId => `/concept/${conceptId}/relatedConcepts`,
    }),
    getConceptsByIncidentReportType: build.query<GetConceptPayload[], ReportType>({
      query: reportType => `/concept/getByIncidentReportType/${reportType}`,
    }),
    createConcept: build.mutation<void, Partial<ConceptMutationPayload>>({
      query(data) {
        return {
          url: '/concept',
          method: 'POST',
          body: data,
        };
      },
    }),
    updateConcept: build.mutation<
      void,
      { conceptId: number; record: Partial<ConceptMutationPayload> }
    >({
      query({ conceptId, record }) {
        return {
          url: `/concept/${conceptId}`,
          method: 'PUT',
          body: record,
        };
      },
    }),
  }),
});

export const {
  useGetConceptsQuery,
  useCreateConceptMutation,
  useUpdateConceptMutation,
} = conceptApi;
