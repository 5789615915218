import {api} from './api';
import {AuditFieldsType, Page, PaginationCriteria} from "../../utils/types";

export type NotifContactMutationRequest = {
  email: string;
  full_name: string;
  disabled: boolean;
};

export type NotifContactQueryResponse = NotifContactMutationRequest & {
  notif_contact_id: number;
} & AuditFieldsType;

export type SearchCriteria = PaginationCriteria & {
  searchText: string;
};

export const notificationContactApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchNotifContacts: build.query<Page<NotifContactQueryResponse>, SearchCriteria>({
      query: (params) => {
        const urlParams = new URLSearchParams(params as unknown as Record<string, string>);
        return `/notificationContact?${urlParams.toString()}`;
      },
    }),
    createNotifContact: build.mutation<
      void,
      Partial<NotifContactMutationRequest>
    >({
      query(data) {
        return {
          url: '/notificationContact',
          method: 'POST',
          body: data,
        };
      },
    }),
    updateNotifContact: build.mutation<
      void,
      {
        notifContactId: number;
        record: Partial<NotifContactMutationRequest>;
      }
    >({
      query({ notifContactId, record }) {
        return {
          url: `/notificationContact/${notifContactId}`,
          method: 'PUT',
          body: record,
        };
      },
    }),
  }),
});

export const {
  useLazySearchNotifContactsQuery,
  useCreateNotifContactMutation,
  useUpdateNotifContactMutation,
} = notificationContactApi;
