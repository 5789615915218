import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton, Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Add, CheckBox, CheckBoxOutlineBlank, Edit } from '@mui/icons-material';

import NotifContactModal from '../../components/Modals/NotifContactModal';
import Header from '../../components/Header/Header';

import {
  NotifContactQueryResponse,
  useCreateNotifContactMutation,
  useLazySearchNotifContactsQuery,
  useUpdateNotifContactMutation,
} from '../../redux/services/notifContact';
import { useMutationToasts } from '../../utils/hooks';
import { PaginationCriteria } from '../../utils/types';

export default function NotifContacts() {
  const DEFAULT: PaginationCriteria = { pageNumber: 1, pageSize: 10 };
  const [searchNotifContacts, searchNotifContactsResult] =
    useLazySearchNotifContactsQuery();
  const [createNotifContact, createNotifContactResult] =
    useCreateNotifContactMutation();
  const [updateNotifContact, updateNotifContactResult] =
    useUpdateNotifContactMutation();
  const [paginationState, setPaginationState] =
    useState<PaginationCriteria>(DEFAULT);
  const [searchText, setSearchText] = useState<string>('');
  const [target, setTarget] = useState('');
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] =
    useState<NotifContactQueryResponse | null>(null);

  useEffect(() => {
    searchNotifContacts({
      ...paginationState,
      searchText,
    });
  }, []);

  useEffect(() => {
    searchNotifContacts({
      ...paginationState,
      searchText,
    });
  }, [paginationState.pageNumber]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const editNotifContactHandler = useCallback(
    (row: any) => {
      setCurrentRow(row);
      setTarget('edit_notif_contact');
      setOpen(true);
    },
    [open, target, currentRow]
  );

  const addNotifContactHandler = useCallback(() => {
    setTarget('add_new_notif_contact');
    setCurrentRow(null);
    setOpen(true);
  }, [open, target]);

  function handleValidSubmitNotifContactModal(formValues) {
    if (target === 'add_new_notif_contact') {
      createNotifContact(formValues);
    } else if (target === 'edit_notif_contact') {
      const notifContactId = currentRow?.notif_contact_id as number;
      updateNotifContact({ notifContactId, record: formValues });
    }
  }

  useMutationToasts({
    isLoading: createNotifContactResult.isLoading,
    isSuccess: createNotifContactResult.isSuccess,
    error: createNotifContactResult.error,
    successMsg: 'El contacto ha sido creado.',
    onSuccess: () => {
      handleClose();
      searchNotifContacts({
        ...paginationState,
        searchText,
      });
    },
  });

  useMutationToasts({
    isLoading: updateNotifContactResult.isLoading,
    isSuccess: updateNotifContactResult.isSuccess,
    error: updateNotifContactResult.error,
    successMsg: 'El contacto ha sido modificado.',
    onSuccess: () => {
      handleClose();
      searchNotifContacts({
        ...paginationState,
        searchText,
      });
    },
  });

  const isNotifContactModalOpen =
    target === 'add_new_notif_contact' ||
    (currentRow !== null && target === 'edit_notif_contact');

  return (
    <>
      <Header />
      <TableContainer component={Paper} sx={{ flexGrow: 1 }}>
        <Table aria-label="simple table" style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell>Correo</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell align="center">Deshabilitado</TableCell>
              <TableCell align="right">
                <Button variant="contained" onClick={addNotifContactHandler}>
                  <Add /> Agregar contacto
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchNotifContactsResult?.data?.records?.map((row) => (
              <TableRow
                key={`notif-contact-row-${row.notif_contact_id}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.full_name}</TableCell>
                <TableCell align="center">
                  {row.disabled ? <CheckBox /> : <CheckBoxOutlineBlank />}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="Modificar"
                    onClick={() => {
                      editNotifContactHandler(row);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {isNotifContactModalOpen && (
            <NotifContactModal
              open={open}
              onClose={handleClose}
              target={target}
              currentRow={currentRow}
              onValidSubmit={handleValidSubmitNotifContactModal}
            />
          )}
        </Table>
      </TableContainer>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={2}
      >
        {searchNotifContactsResult?.isSuccess && (
          <Pagination
            count={Math.ceil(searchNotifContactsResult?.data?.totalRecords / DEFAULT.pageSize)}
            onChange={(event, page) => {
              setPaginationState({
                ...paginationState,
                pageNumber: page,
              })
            }}
          />
        )}
      </Box>
    </>
  );
}
