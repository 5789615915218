export const formFieldsGuide = [
  {
    field: 'name',
    label: 'Nombre',
  },
  {
    field: 'category_id',
    label: 'Category',
  },
  {
    field: 'description_html',
    label: 'Descripción 1',
  },
  {
    field: 'description2_html',
    label: 'Descripción 2',
  },
  {
    field: 'hashtags',
    label: 'Hashtags',
  },
  {
    field: 'image_url',
    label: 'Imagen',
  },
  {
    field: 'detail_image_url',
    label: 'Imagen c/leyenda',
  },
];

export const formFieldsModerate = [
  {
    field: 'status',
    label: 'Estatus',
  },
  {
    field: 'flagged',
    label: 'Censurado',
  },
  {
    field: 'flagged_comment',
    label: 'Comentario de moderación',
  },
];

export const formFieldsNotifContacts = [
  {
    field: 'email',
    label: 'Correo electrónico',
  },
  {
    field: 'full_name',
    label: 'Nombre completo',
  },
  {
    field: 'disabled',
    label: 'Deshabilitado',
  },
];


export const formFieldsEditModerator = [
  {
    field: 'login',
    label: 'Correo',
  },
  {
    field: 'full_name',
    label: 'Nombre completo',
  },
  {
    field: 'role',
    label: 'Rol',
  },
  {
    field: 'disabled',
    label: 'Deshabilitado',
  },
];

export const formFieldsPassword = [
  {
    field: 'password',
    label: 'Contraseña',
  },
  {
    field: 'repeatPassword',
    label: 'Confirme contraseña',
  },
];


export const formFieldsAddModerator = [
  ...formFieldsEditModerator,
  ...formFieldsPassword
];

