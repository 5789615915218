import {api} from './api';

export type AuthRequest = {
  login: string,
  password: string,
};

export type AuthResponse = {
  jwttoken: string;
};

export const authApi = api.injectEndpoints({
  endpoints: build => ({
    postAuth: build.query<AuthResponse, Partial<AuthRequest>>({

      query(data) {
        return {
          url: '/public/login',
          method: 'POST',
          headers: {},
          body: data,
        };
      }
    }),
  }),
});

export const {
  useLazyPostAuthQuery,
} = authApi;
