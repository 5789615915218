import React, {useEffect} from 'react';
import { useFormik } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

import { validationSchemaChangePassword } from '../../constants/ValidationSchemas';
import { formFieldsPassword } from '../../constants/FormFields';
import {toast} from "react-toastify";


type formikFields = 'password' | 'repeatPassword';

type Props = {
  open: boolean;
  onClose(): void;
  currentRow: any;
  onValidSubmit(formValues?: FormValues): void;
};

type FormValues = {
  password: string;
  repeatPassword: string;
};

export default function PasswordModal({
  open,
  onClose = () => {},
  onValidSubmit = () => {},
  currentRow,
}: Props) {

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    } as FormValues,
    onSubmit: () => handleSubmit(),
    validationSchema: validationSchemaChangePassword,
  });

  useEffect(() => {
    formik.resetForm();
  }, [currentRow]);

  function handleSubmit() {
    if (formik.values.password !== formik.values.repeatPassword) {
      toast.error('Contraseñas no son iguales.');
      return;
    }
    onValidSubmit(formik.values);
  }

  function handleClose() {
    onClose();
    formik.resetForm();
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <DialogTitle>
          <div>Cambiar contraseña</div>
        </DialogTitle>
        <DialogContent>
          {formFieldsPassword.map(({ field, label }) => (
            <div key={field}>
              <TextField
                error={
                  !!(
                    formik.touched[field as formikFields] &&
                    formik.errors[field as formikFields]
                  )
                }
                margin="dense"
                id={field}
                label={label}
                type={ ["password", "repeatPassword"].includes(field) ? "password" : "text" }
                fullWidth
                variant="standard"
                name={field}
                value={formik.values[field as formikFields]}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.errors[field as formikFields] &&
                formik.touched[field as formikFields] && (
                  <div>{formik.errors[field as formikFields]}</div>
                )}
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            onClick={handleSubmit}
            type="button"
          >
            Cambiar contraseña
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

