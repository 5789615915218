import React, { useCallback, useState } from 'react';
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Add, Edit } from '@mui/icons-material';

import ConfirmModal from '../../components/Modals/ConfirmModal';
import ConceptModal from '../../components/Modals/ConceptModal';
import Header from '../../components/Header/Header';

import './Concepts.css';

import {
  useCreateConceptMutation,
  useGetConceptsQuery,
  useUpdateConceptMutation,
} from '../../redux/services/concept';
import {
  ConceptCategory,
  useGetConceptCategoriesQuery,
} from '../../redux/services/conceptCategory';
import { GetConceptPayload } from '../../utils/types';
import {useMutationToasts} from "../../utils/hooks";

export default function Concepts() {
  const concepts = useGetConceptsQuery();
  const [createConcept, createConceptResult] = useCreateConceptMutation();
  const [updateConcept, updateConceptResult] = useUpdateConceptMutation();
  const conceptCategories = useGetConceptCategoriesQuery();
  const [target, setTarget] = useState('');
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentRow, setCurrentRow] = useState<GetConceptPayload | null>(null);

  const confirmModalClose = useCallback(() => {
    setOpenConfirm(false);
  }, [openConfirm]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setTarget('');
  }, [open]);

  const handleEditConcept = useCallback(
    (row: GetConceptPayload) => {
      setCurrentRow(row);
      setTarget('edit_concept');
      setOpen(true);
    },
    [open, target, currentRow]
  );

  const handleAddConcept = useCallback(() => {
    setCurrentRow(null);
    setTarget('add_concept');
    setOpen(true);
  }, [open, target]);

  function handleValidSubmitConceptModal(formValues) {
    if (target === 'add_concept') {
      createConcept(formValues);
    } else if (target === 'edit_concept') {
      const id = currentRow?.concept_id as number;
      updateConcept({ conceptId: id, record: formValues });
    }
  }

  useMutationToasts({
    isLoading: createConceptResult.isLoading,
    isSuccess: createConceptResult.isSuccess,
    error: createConceptResult.error,
    successMsg: 'El concepto ha sido creado.',
    onSuccess: () => {
      handleClose();
      concepts.refetch();
    },
  });

  useMutationToasts({
    isLoading: updateConceptResult.isLoading,
    isSuccess: updateConceptResult.isSuccess,
    error: updateConceptResult.error,
    successMsg: 'El usuario ha sido modificado.',
    onSuccess: () => {
      handleClose();
      concepts.refetch();
    },
  });

  const isConceptModalOpen =
    target === 'add_concept' ||
    (currentRow !== null && target === 'edit_concept');

  const isConfirmModalOpen =
    (currentRow !== null && target === 'disable_concept') ||
    (currentRow !== null && target === 'delete_concept');

  return (
    <>
      <Header />
      <TableContainer component={Paper} sx={{ flexGrow: 1 }}>
        <Table aria-label="simple table" style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">ID</TableCell>
              <TableCell align="left">Nombre</TableCell>
              <TableCell align="left">Categoría</TableCell>
              <TableCell align="left">Descripción 1</TableCell>
              <TableCell align="left">Descripción 2</TableCell>
              <TableCell align="left">Hashtags</TableCell>
              <TableCell align="left">Imagen</TableCell>
              <TableCell align="left">Imagen c/leyenda</TableCell>
              <TableCell align="center">
                <Button variant="contained" onClick={handleAddConcept}>
                  <Add /> Agregar
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {concepts?.data?.records?.map((row) => (
              <TableRow
                key={row.concept_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{row.concept_id}</TableCell>
                <TableCell align="left">
                  <div className="description_row" title={row.name}>
                    {row.name}
                  </div>
                </TableCell>
                <TableCell align="left">{row.category_name}</TableCell>
                <TableCell align="left">
                  <div className="description_row" title={row.description_html}>
                    {row.description_html}
                  </div>
                </TableCell>
                <TableCell align="left">
                  <div
                    className="description_row"
                    title={row.description2_html}
                  >
                    {row.description2_html}
                  </div>
                </TableCell>
                <TableCell align="left">
                  <div className="hashtags_row" title={row.hashtags}>
                    {row.hashtags}
                  </div>
                </TableCell>
                <TableCell align="left">
                  {/*<Link to={row.image_url}>*/}
                  <div className="picture_row">{row.image_url}</div>
                  {/*</Link>*/}
                </TableCell>
                <TableCell align="left">
                  {/*<Link to={row.image_url}>*/}
                  <div className="picture_row">{row.detail_image_url}</div>
                  {/*</Link>*/}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label="Modificar"
                    onClick={() => handleEditConcept(row)}
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isConceptModalOpen && (
          <ConceptModal
            open={open}
            onClose={handleClose}
            target={target}
            currentRow={currentRow}
            conceptCategories={
              conceptCategories?.data?.records || ([] as ConceptCategory[])
            }
            onValidSubmit={handleValidSubmitConceptModal}
          />
        )}
        {isConfirmModalOpen && (
          <ConfirmModal
            openConfirm={openConfirm}
            confirmModalClose={confirmModalClose}
            target={target}
            currentRow={currentRow}
          />
        )}
      </TableContainer>
    </>
  );
}

