import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

import {
  validationSchemaNotifContact,
} from '../../constants/ValidationSchemas';
import {
  formFieldsNotifContacts,
} from '../../constants/FormFields';

type formikFields = 'email' | 'full_name' | 'disabled';

type ModeratorModalProps = {
  open: boolean;
  onClose(): void;
  target: string;
  currentRow: any;
  onValidSubmit(formValues: FormValues): void;
};

type FormValues = {
  email?: string;
  full_name?: string;
  disabled: boolean;
};


const ModeratorModal = ({
  open,
  onClose = () => {},
  target,
  currentRow,
  onValidSubmit = () => {},
}: ModeratorModalProps) => {

  const formik = useFormik({
    initialValues: {
      email: currentRow?.email ?? '',
      full_name: currentRow?.full_name ?? '',
      disabled: currentRow?.disabled ?? false,
    } as FormValues,
    onSubmit: () => handleSubmit(),
    validationSchema: validationSchemaNotifContact,
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        email: currentRow?.email ?? '',
        full_name: currentRow?.full_name ?? '',
        disabled: currentRow?.disabled ?? false,
      }
    });
  }, [currentRow]);

  function handleSubmit() {
    onValidSubmit(formik.values);
  }

  function handleClose() {
    onClose();
    formik.resetForm();
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <DialogTitle>
          {target === 'add_new_notif_contact' ? (
            <div>Agregar contacto</div>
          ) : (
            <div>Modificar contacto</div>
          )}
        </DialogTitle>
        <DialogContent>

          {/*<pre style={{fontStyle: 'small'}}>{JSON.stringify(currentRow, null, 2)}</pre>*/}

          {formFieldsNotifContacts.map(({ field, label }) => {
            if(field === 'disabled') {
              return (
                <div key={field}>
                  <FormControlLabel control={<Checkbox
                    checked={formik.values.disabled}
                    id={field}
                    name={field}
                    value={formik.values[field as formikFields]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />} label={label} />
                </div>
              );
            }
            return (
              <div key={field}>
                <TextField
                  error={Boolean(
                    formik.touched[field as formikFields] &&
                      formik.errors[field as formikFields]
                  )}
                  helperText={
                    formik.touched[field as formikFields] &&
                    formik.errors[field as formikFields]
                  }
                  margin="dense"
                  id={field}
                  label={label}
                  type={field === 'email' ? 'email' : 'text'}
                  fullWidth
                  multiline
                  variant="standard"
                  name={field}
                  value={formik.values[field as formikFields]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            onClick={handleSubmit}
            type="button"
          >
            {target === 'add_new_notif_contact' ? 'Agregar' : 'Modificar'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModeratorModal;
