import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Details,
  Edit,
} from '@mui/icons-material';

import Header from '../../components/Header/Header';
import IncidentReportModal from '../../components/Modals/IncidentReportModal';
import {
  useLazyGetFilterReportsQuery,
  useLazyGetReportImagesQuery,
  usePutReportModerationMutation,
} from '../../redux/services/report';
import { useMutationToasts } from '../../utils/hooks';
import {GetReportPayload, PaginationCriteria} from '../../utils/types';
import IncidentReportDetailsModal from '../../components/Modals/IncidentReportDetailsModal';
import { getReportTypeLabel } from '../../utils/utils';



export default function IncidentReports() {
  const DEFAULT: PaginationCriteria = { pageNumber: 1, pageSize: 10 };
  const [getFilterReports, reportsResult] = useLazyGetFilterReportsQuery();
  const [getReportImages, reportImagesResult] = useLazyGetReportImagesQuery();
  const [putReportModeration, putReportModerationResult] = usePutReportModerationMutation();
  const [paginationState, setPaginationState] = useState<PaginationCriteria>(DEFAULT);
  const [searchText, setSearchText] = useState<string>('');

  const [target, setTarget] = useState('');
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentRow, setCurrentRow] = useState<GetReportPayload | null>(null);

  useEffect(() => {
    getFilterReports({
      ...paginationState,
      text: searchText,
      status: '',
      type: '',
    });
  }, []);

  useEffect(() => {
    getFilterReports({
      ...paginationState,
      text: searchText,
      status: '',
      type: '',
    });
  }, [paginationState.pageNumber]);

  useEffect(() => {
    if (target === 'details_report' && currentRow?.incident_report_id) {
      getReportImages(currentRow?.incident_report_id);
    }
  }, [currentRow, target]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const handleClick = (row: GetReportPayload) => {
    if (row) {
      setCurrentRow(row);
    }
  };

  const handleEditPressed = useCallback(
    (row: any) => {
      handleClick(row);
      setTarget('edit_report');
      setOpen(true);
    },
    [open, target, currentRow]
  );

  const handleDetailPressed = useCallback(
    (row: any) => {
      handleClick(row);
      setTarget('details_report');
      setOpen(true);
    },
    [open, target, currentRow]
  );

  function handleValidSubmitEditModal(formValues) {
    const incidentReportId = currentRow?.incident_report_id as number;
    putReportModeration({ incidentReportId, record: formValues });
  }

  useMutationToasts({
    isLoading: putReportModerationResult.isLoading,
    isSuccess: putReportModerationResult.isSuccess,
    error: putReportModerationResult.error,
    successMsg: 'El reporte ha sido modificado.',
    onSuccess: () => {
      handleClose();
      getFilterReports({
        ...paginationState,
        text: searchText,
        status: '',
        type: '',
      });
    },
  });

  const isModeratorTabModalOpen =
    currentRow !== null && target === 'edit_report';

  const isDetailsModalOpen =
    currentRow !== null && target === 'details_report';


  return (
    <>
      <Header />
      {/*<pre style={{fontStyle: 'small'}}>{JSON.stringify(reportsResult.data, null, 2)}</pre>*/}

      <TableContainer component={Paper} sx={{ flexGrow: 1 }}>
        <Table aria-label="simple table" style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">ID</TableCell>
              <TableCell align="left">Tipo</TableCell>
              <TableCell align="left">Coordenadas</TableCell>
              <TableCell align="left">Título</TableCell>
              <TableCell align="left">Descripción</TableCell>
              <TableCell align="left">Estatus</TableCell>
              <TableCell align="center">Censurado</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportsResult?.data?.records.map((row) => (
              <TableRow
                key={row.incident_report_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{row.incident_report_id}</TableCell>
                <TableCell align="left">{getReportTypeLabel(row.type)}</TableCell>
                <TableCell align="left">
                  {row.x_coordinate?.toFixed(6)},{row.y_coordinate?.toFixed(6)}
                </TableCell>
                <TableCell align="left">
                  <div>{row.title}</div>
                </TableCell>
                <TableCell align="left">
                  <div className="description_row">{row.description}</div>
                </TableCell>
                <TableCell align="left">
                  <div className="">{row.status === 'RESOLVED' ? 'Resuelto' : 'Sin resolver'}</div>
                </TableCell>
                <TableCell align="center">
                  {row.flagged ? <CheckBox /> : <CheckBoxOutlineBlank />}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="details"
                    title="Ver detalles"
                    onClick={() => {
                      handleDetailPressed(row);
                    }}
                  >
                    <Details />
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    title="Modificar"
                    onClick={() => {
                      handleEditPressed(row);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isModeratorTabModalOpen && (
          <IncidentReportModal
            open={open}
            onClose={handleClose}
            target={target}
            currentRow={currentRow}
            onValidSubmit={handleValidSubmitEditModal}
          />
        )}
        {isDetailsModalOpen && (
          <IncidentReportDetailsModal
            open={open}
            onClose={handleClose}
            target={target}
            currentRow={currentRow}
            reportImages={reportImagesResult?.data || []}/>
        )}
        {isModeratorTabModalOpen && (
          <IncidentReportModal
            open={open}
            onClose={handleClose}
            target={target}
            currentRow={currentRow}
            onValidSubmit={handleValidSubmitEditModal}
          />
        )}
      </TableContainer>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={2}
      >
        {reportsResult?.isSuccess && (
          <Pagination
            count={Math.ceil(reportsResult?.data?.totalRecords / DEFAULT.pageSize)}
            onChange={(event, page) => {
              setPaginationState({
                ...paginationState,
                pageNumber: page,
              })
              console.debug({event, page})
            }}
          />
        )}
      </Box>
    </>
  );
};
