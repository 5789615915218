export function getReportTypeLabel(reportType: string) {
  switch (reportType) {
    case 'dumping':
      return 'Vertedero clandestino';
    case 'excavation':
      return 'Movimiento de tierra';
    case 'tree_cutting':
      return 'Corte de árbol';
    case 'water_pollution':
      return 'Contaminación de agua';
    case 'water_leak':
      return 'Pérdida de agua potable';
    case 'other':
      return 'Otro';
    default:
      return 'Desconocido'
  }
}