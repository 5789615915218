import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface IConfirmModal {
  openConfirm: boolean;
  confirmModalClose: () => void;
  target: string;
  currentRow: any;
}

const ConfirmModal = ({
  openConfirm,
  confirmModalClose,
  target,
  currentRow,
}: IConfirmModal) => {
  return (
    <div>
      <Dialog
        fullWidth
        open={openConfirm}
        onClose={confirmModalClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {target === 'delete_guide' ? (
            <p>{'Are you sure you want to delete guide?'}</p>
          ) : target === 'disable_guide' ? (
            <p>{'Are you sure you want to disable guide?'}</p>
          ) : target === 'delete_moderator' ? (
            <p>{'Are you sure you want to delete this moderator?'}</p>
          ) : (
            <p>{'Are you sure you want to delete report?'}</p>
          )}
        </DialogTitle>
        <DialogContent>
          {target === 'delete_guide' ? (
            <DialogContentText>
              This will cause the data of this guide to be permanently deleted.
            </DialogContentText>
          ) : target === 'disable_guide' ? (
            <DialogContentText>
              Disabling this guide will result in it not being displayed in the
              mobile app
            </DialogContentText>
          ) : target === 'delete_moderator' ? (
            <DialogContentText>
              This will cause that user to no longer have access to the admin
              panel
            </DialogContentText>
          ) : (
            <DialogContentText>
              This will cause the data of this report to be permanently deleted.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={confirmModalClose}>
            Disagree
          </Button>
          <Button onClick={confirmModalClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmModal;
